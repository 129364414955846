import {  LOGIN_RESPONSE ,
    LOGOUT_RESPONSE,
    FORGOT_PASSWORD_RESPONSE,
    RESET_PASSWORD_RESPONSE,
    VERIFY_OTP_RESPONSE
} from "../action/type";

const Initial_state = {
    login_data:"",
    forgotPassword_data :"",
    resetPassword_data:"",
    verifyotp_data:""
}

export default (state = Initial_state, action) => {
    const { type, payload } = action;
    // console.log("payload auth ::",payload)
    switch (type) {
     
            case LOGIN_RESPONSE:
                if (payload.status) {
                    return {
                        ...state,
                        login_data: payload?.data
                    } 
                };
                case LOGOUT_RESPONSE:
                            localStorage.removeItem("token");
                            return {
                                ...state,
                                login_data:""
                            }
                case FORGOT_PASSWORD_RESPONSE:
                    if (payload.status) {
                        return {
                            ...state,
                            forgotPassword_data: payload?.data
                        } 
                    };
                    case VERIFY_OTP_RESPONSE:
                        if (payload.status) {
                            return {
                                ...state,
                                verifyotp_data: payload?.data
                            } 
                        };
                    case RESET_PASSWORD_RESPONSE:
                        if (payload.status) {
                            return {
                                ...state,
                                resetPassword_data: payload?.data
                            } 
                        };

        default:
            return state;
    }
}