import {
    HOME_DATA_RESPONSE,
    ADMIN_LIST_RESPONSE, 
} from "../action/type";

const Initial_state = {
    dashboard_data: "",
    admin_list_data:"",
    driver_list_data:"",
    driver_detail_data:"",
    driver_search_data:""
}

export default (state = Initial_state, action) => {
    const { type, payload } = action;
    switch (type) {
        case HOME_DATA_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    dashboard_data: payload?.data
                } 
            };
        case ADMIN_LIST_RESPONSE:
            if (payload.status) {
                return {
                    ...state,
                    admin_list_data: payload?.data
                } 
            };
      
        default:
            return state;
    } 
}