
// import thunk from "redux-thunk";
// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import root from "../src/redux/reducer/index";

// const initialState = {};
// const middleware = [thunk];

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['reducer1', 'reducer2']
// };

// const persistedReducer = persistReducer(persistConfig, root);

// const store = createStore(
//   persistedReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// const persistor = persistStore(store);

// export { store, persistor };



//persisit work
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import thunk from 'redux-thunk';
import root from "../src/redux/reducer/index";

// Set up configuration for persistence
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'] // Array of reducers to persist
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, root);

// Create the store
// const store = createStore(
//   persistedReducer,
//   // compose(
//     applyMiddleware(thunk),
//     // window.__REDUX_DEVTOOLS_EXTENSION_ && window.__REDUX_DEVTOOLS_EXTENSION_()
//   // )
// );
const store = createStore(
  persistedReducer,
  // compose(
    applyMiddleware(thunk),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
);



// Create the persistor
const persistor = persistStore(store);

export { store, persistor };