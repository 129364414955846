import { combineReducers } from "redux";
import auth from "./auth";
import home from "./home";


const root = combineReducers({
    auth,
    home,

});

export default root;