import './App.css';
import "./css/style.css"
import "./css/responsive.css"
// import "./css/dashboard.css"
import React, { lazy, Suspense } from 'react'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "../src/store";
import { ToastContainer, toast } from 'react-toastify';
import { HashRouter, Routes } from 'react-router-dom';

const AppRouter = lazy(() => import('./routes/index'));
function App() {

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<div className={'text-center mt-3'}>Loading Please Wait....</div>}>
          <HashRouter>
            <AppRouter />
            </HashRouter>
          </Suspense>
        </PersistGate>
      </Provider>
      <ToastContainer />

    </>
  );
}

export default App;
